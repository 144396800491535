import React, { useState } from "react";
import {
  Pane,
  Button,
  Text,
  Image,
  TextInputField,
  toaster,
} from "evergreen-ui";
import logo from "../../assets/img/icon.svg";
import "./Signup.css";
import { apiEndPoint } from "../../utils";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Signup({ setUserLoggedIn, setIsNewInstalled, isNewInstalled }) {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    otp: "", // Added OTP to credentials
  });

  const [showOtpInput, setShowOtpInput] = useState(false); // Control OTP screen
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSignup = async () => {
    setLoading(true);
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const req = {
        method: "POST",
        body: JSON.stringify(credentials),
        headers: new Headers(headers),
      };
      const res = await fetch(apiEndPoint + "/auth/signup", req);
      const data = await res.json();
      if (data?.userId) {
        localStorage.setItem("userId", data.userId); // Store temp ID
        Cookies.set("userId", data.userId);
        setShowOtpInput(true); // Show OTP input screen
      } else {
        throw new Error(data.message || "Signup failed");
      }
    } catch (error) {
      toaster.danger(error?.message || "Signup failed, please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpVerification = async () => {
    setLoading(true);
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const req = {
        method: "POST",
        body: JSON.stringify({
          userId: localStorage.getItem("userId"),
          otp: credentials.otp,
          forOTP: "signup",
        }),
        headers: new Headers(headers),
      };
      const res = await fetch(apiEndPoint + "/auth/verify-otp", req);
      const data = await res.json();
      if (data.jwt) {
        localStorage.setItem("jwt", data.jwt);
        localStorage.setItem("user", JSON.stringify(data.user));
        Cookies.set("jwt", data.jwt);
        Cookies.set("userId", data.userId);
        navigate("/package");
      } else {
        throw new Error(data.message || "OTP verification failed");
      }
    } catch (error) {
      toaster.danger(error?.message || "Invalid OTP, please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to resend OTP
  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const req = {
        method: "POST",
        body: JSON.stringify({
          userId: localStorage.getItem("userId"),
          forOTP: "signup",
        }),
        headers: new Headers(headers),
      };
      const res = await fetch(apiEndPoint + "/auth/resend-otp", req);
      const data = await res.json();
      if (data) {
        toaster.success("OTP has been resent to your email.");
      } else {
        throw new Error(data.message || "Failed to resend OTP");
      }
    } catch (error) {
      toaster.danger(
        error?.message || "Unable to resend OTP, please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Pane
      display="grid"
      justifyItems="center"
      textAlign="center"
      gridGap={20}
      placeContent="center"
      height="100vh"
    >
      <Image width={80} src={logo} alt="logo" />
      <Text size={600} width={600}>
        {!showOtpInput ? (
          "Let's get started with your email and a password."
        ) : (
          <>
            Enter the OTP sent to your email <br />
            <b>{credentials?.email}</b>
          </>
        )}
      </Text>

      {!showOtpInput ? (
        // Signup Form
        <Pane width="60%" display="block" textAlign="left">
          <TextInputField
            label="Email"
            name="email"
            onChange={handleChange}
            placeholder="Enter your email"
          />
          <TextInputField
            label="Password"
            name="password"
            onChange={handleChange}
            type="password"
            placeholder="Enter your password"
          />
          <Button
            appearance="primary"
            size="large"
            onClick={handleSignup}
            isLoading={loading}
            position="relative"
            left="50%"
            transform="translateX(-50%)"
          >
            Create Account
          </Button>
        </Pane>
      ) : (
        // OTP Input Form
        <Pane width="60%" display="block" textAlign="left">
          <TextInputField
            label="OTP"
            name="otp"
            onChange={(e) => {
              const { name, value } = e.target;
              if (/^\d*$/.test(value) && value.length <= 6) {
                setCredentials((prev) => ({
                  ...prev,
                  [name]: value,
                }));
              }
            }}
            placeholder="Enter the 6-digit OTP"
            value={credentials.otp}
          />
          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={10} // Add some margin to separate them from the OTP input
          >
            <Button
              appearance="primary"
              size="large"
              onClick={handleOtpVerification}
              isLoading={loading}
              marginRight={10} // Space between the two buttons
            >
              Verify OTP
            </Button>
            <Button appearance="primary" size="large" onClick={handleResendOtp}>
              Resend OTP
            </Button>
          </Pane>
        </Pane>
      )}

      {!showOtpInput && (
        <Pane
          marginTop={10}
          cursor="pointer"
          onClick={() => navigate("/login")}
        >
          <Text>
            <p className="line">
              <span>or</span>
            </p>
          </Text>
          <Text>Log In</Text>
        </Pane>
      )}
    </Pane>
  );
}

export default Signup;
