import React, { useEffect, useState } from "react";
import { Pane, Text, Link, Button, Image, Heading } from "evergreen-ui";
import { useNavigate } from "react-router-dom";

const LimeInstructions = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let intv;
    const handleMessage = (event) => {
      try {
        // Check if the message received is the correct one
        if (event.data.type === "getCsrfToken" && event.data.data) {
          clearInterval(intv);
       
          setError(false);
          navigate("/welcome"); // Navigate to the welcome page
        } else if (event.data.type === "getCsrfToken" && event.data.error) {
          clearInterval(intv);
          setError(true); // If there's an error, set error to true
        }
      } catch (error) {
        clearInterval(intv);
        setError(true); // If any error occurs, assume extension is unavailable
      }
    };

    // Set interval to send a message every second
    intv = setInterval(() => {
      window.postMessage({ type: "getCsrfToken" }, "*");
    }, 1000);

    // Listen for messages from the extension
    window.addEventListener("message", handleMessage);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [navigate]);

  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={40}
      maxWidth={400}
      margin="auto"
    >
      <h3 style={{ marginBottom: 20 }}>
        <b>Welcome to Lime Instructions</b>
      </h3>

      {/* Step 1: LinkedIn Login Instruction */}
      <Heading size={600} marginBottom={16} textAlign="center">
        <b>Please log into </b>
        <Link href="https://linkedin.com" target="_blank" color="blue">
          LinkedIn
        </Link>
      </Heading>

      {/* Step 2: Click Lime Extension Instruction */}
      <Text size={500} marginTop={24} textAlign="center">
        Then, click the Lime extension:
      </Text>
      <Image
        src="./icon-128.png"
        alt="Lime Icon"
        width={60}
        height={60}
        marginY={12}
      />

      {/* Step 3: Open Lime in a New Tab Instruction */}
      <Text size={500} marginTop={24} textAlign="center">
        Then open Lime in a new tab:
      </Text>
      <div style={{ width: "30px", marginTop: "15px" }}>
        <svg
          className="svg-inline--fa fa-expand-alt color-expand-window animation-expand-window-large"
          style={{ fontSize: 30 }}
          aria-hidden="true"
          focusable="false"
          data-prefix="fad"
          data-icon="expand-alt"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-fa-i2svg=""
        >
          <g className="fa-duotone-group">
            <path
              className="fa-secondary"
              fill="currentColor"
              d="M0 344L0 488c0 13.3 10.7 24 24 24l144 0c9.7 0 18.5-5.8 22.2-14.8s1.7-19.3-5.2-26.2l-39-39 87-87c4.7-4.7 7-10.8 7-17s-2.3-12.3-7-17l-32-32c-4.7-4.7-10.8-7-17-7s-12.3 2.3-17 7l-87 87L41 327c-6.9-6.9-17.2-8.9-26.2-5.2S0 334.3 0 344z"
            />
            <path
              className="fa-primary"
              fill="currentColor"
              d="M488 0L344 0c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l39 39-87 87c-9.4 9.4-9.4 24.6 0 33.9l32 32c9.4 9.4 24.6 9.4 33.9 0l87-87 39 39c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2l0-144c0-13.3-10.7-24-24-24z"
            />
          </g>
        </svg>
      </div>

      {/* Extension Installation Section */}
      <Pane
        marginTop={32}
        padding={16}
        background="tint2"
        borderRadius={4}
        textAlign="center"
      >
        <Text size={500} marginBottom={8}>
          Need to install the Lime extension?
        </Text>
        <Button
          appearance="primary"
          intent="none"
          is="a"
          href="https://chrome.google.com/webstore/detail/Lime-extension-link"
          target="_blank"
          marginTop={8}
        >
          Install the Lime extension now
        </Button>
      </Pane>

      {/* YouTube Video Section */}
      <Pane marginTop={32} width="100%" textAlign="center">
        <iframe
          width="100%"
          height="225"
          src="https://www.youtube.com/embed/xZNvPw0vMMk"
          title="Lime Extension Introduction Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ borderRadius: "4px" }}
        ></iframe>
      </Pane>
    </Pane>
  );
};

export default LimeInstructions;
