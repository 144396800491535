import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  Pane,
  Button,
  IconButton,
  TextInputField,
  toaster,
  Tooltip,
  Text,
  EyeOffIcon,
} from "evergreen-ui";
import { debounce } from "debounce";
import axios from "axios";
import "./Feed.css";
import Pagination from "./components/Pagination";
import Sorting from "./components/Sorting";
import ReactHtmlParser from "react-html-parser";

import {
  formatTimeStamp,
  cleanStr,
  limeTextConvertor,
  replaceTextToLink,
  apiEndPoint,
} from "../../../utils";
import { useSearchParams } from "react-router-dom";

/**
 * @desc React component
 * @param {Object} Props
 * @returns Table component
 */
let isSearchPressed = false;
const Feed = forwardRef((props, ref) => {
  // STATES

  useImperativeHandle(ref, () => ({
    refreshData() {
      fetchData();
    },
  }));
  const [loading, setLoading] = useState(false);
  const [connections, setConnections] = useState([]);
  const [showRemove, setRemove] = useState(false);
  const [toRemove, setToRemove] = useState();

  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({
    count: 25,
    currentPage: 1,
  });
  const [totalResults, setTotalResults] = useState(0);
  const [sorting, setSorting] = useState(
    localStorage.getItem("selectedFeedSort") || "postDate_desc"
  );
  let token = localStorage.getItem("jwt");

  let loggedInUser = localStorage.getItem("user");
  loggedInUser = JSON.parse(loggedInUser);

  const user = localStorage.getItem("profile");
  const userId = JSON.parse(user) || null;
  const [inputSearch, setInputSearch] = useState("");
  const [fetchSearch, setFetchSearch] = useState(props.searchValue);
  const [linkedInUser, setLinkedInUser] = useState(true);
  // TODO
  const [fetchLoading, setFetchLoading] = useState([]);
  const [inputs, setInputsState] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") || null;

  const [options] = useState(
    [
      { label: "Anniversaries", value: "WORK_ANNIVERSARY_PROP" },
      { label: "Birthdays", value: "BIRTHDAY_PROP" },
      { label: "Job Changes", value: "JOB_CHANGE_PROP" },
    ].map((data) => data)
  );

  const [selectedItemsState, setSelectedItems] = useState(
    JSON.parse(localStorage.getItem("selectedItemsState")) || []
  );

  const [isLiked, setLiked] = useState([]);
  // -----
  const componentMounted = useRef(true);
  /**
   * @desc Send a fetch req to the server to get back notifications
   * @param {Number} [start] The start index of the results for pagination
   * @param {Number} [count] The no. of results per page for pagination
   * @param {String} [sortBy] the field to sort in
   * @param {String} [sortOrder] the order to sort in
   * @param {Array} [searchIn] the fields for text search
   * @param {Array} [search] the texts to search per field
   * @return {Object} - notifications data from the server as per the filters
   */
  function getStorage(key = "token") {
    return new Promise((resolve) => {
      resolve(localStorage.getItem("jwt"));

      // if (key) chrome.storage.sync.get(key, (resp) => resolve(resp[key]));
      // else chrome.storage.sync.get((resp) => resolve(resp[key]));
    });
  }

  async function fetchWatchList(start, count, sortBy, sortOrder, fetchSearch) {
    let searchUrl = ``;
    if (fetchSearch !== "") {
      searchUrl = `&search=${fetchSearch}`;
    }

    const { data } = await axios.get(
      `${apiEndPoint}/feed?start=${start}&count=${count}&sortBy=${sortBy}&sortOrder=${sortOrder}${searchUrl}`,
      {
        headers: {
          liuser: userId?._id,
          authorization: "Bearer " + token,
        },
      }
    );

    return data;
  }

  // -----

  /**
   * @desc Calls fetchNotifications and sets the results to component states
   */

  const fetchData = async () => {
    //setLoading(true);
    const { data, meta } = await fetchWatchList(
      pagination.count * (isSearchPressed ? 0 : pagination.currentPage - 1),
      pagination.count,
      (sorting || "postDate_desc").split("_")[0],
      (sorting || "postDate_desc").split("_")[1] === "asc" ? 1 : -1,
      fetchSearch || inputSearch || ""
    );
    isSearchPressed = false;
    const connectionsArr = data.feeds;

    setTotalResults(meta.totalResults);
    setConnections(connectionsArr);
    setFetchLoading(connectionsArr.map((cur) => false));
    setLoading(false);
  };
  const handleRemove = () => {
    setRemove(false);
  };
  // USE EFFECTS

  // runs when component mounts

  // runs when any of the following state changes
  useEffect(() => {
    if (search != null) {
      setInputSearch(search);
      isSearchPressed = true;
      setFetchSearch(search);
    }
    // Remove a query parameter
    setSearchParams((params) => {
      params.delete("search");
      return params;
    });
    if (userId?._id) {
      fetchData();
    }
  }, [pagination, sorting, fetchSearch, inputSearch]);

  useEffect(() => {
    if (search != null) {
      setInputSearch(search);
      if (userId?._id) {
        fetchData();
      }
    }
    // Remove a query parameter
    setSearchParams((params) => {
      params.delete("search");
      return params;
    });
  }, [search != null]);
  // HANDLERS

  /**
   * @desc Sends Message to bckg to collect complete data of the current row connection and updates the server and current state accordingly
   * @param {Object} [cell] - The cell object passed in table column
   */

  const handleFetchSearch = (e) => {
    const { value } = e.target;
    isSearchPressed = true;
    setFetchSearch(value);
  };
  const gotoNotification = (profile) => {
    props.setSelectedTab("Notifications");
    props.setSearchValue(profile.fullName);
  };
  const handler = useCallback(debounce(handleFetchSearch, 300), []);

  const handleInputSearch = (e) => {
    const { value } = e.target;
    setInputSearch(value);
    isSearchPressed = true;
    handler(e);
  };
  function handleChange(e, i) {
    const { name, value } = e.target;
    setInputsState((prev) => {
      const arr = [...prev];
      arr[i] = value;
      return arr;
    });
  }
  async function updateFeed({ reaction, _id }) {
    try {
      let token = localStorage.getItem("jwt");
      let loggedInUser = localStorage.getItem("linkedInUser");
      loggedInUser = JSON.parse(loggedInUser);

      // Ensure token and loggedInUser exist
      if (!token || !loggedInUser) {
        throw new Error("Token or loggedInUser not found in localStorage.");
      }

      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        liuser: loggedInUser._id,
        authorization: "Bearer " + token,
      };

      let req = {
        method: "PUT",
        body: JSON.stringify({ reaction }), // Only sending reaction in the body
        headers: new Headers(headers),
      };

      const res = await fetch(`${apiEndPoint}/feed/update/${_id}`, req);
      await fetchData();
      // Check if the response is OK (status 200-299)
      if (!res.ok) {
        throw new Error(
          `Failed to update feed: ${res.status} ${res.statusText}`
        );
      }

      let result = await res.json();
      return result;
    } catch (error) {
      console.error("Error updating feed:", error);
      return false;
    }
  }

  async function setLikeOption(event, connection, reaction) {
    await updateFeed({ reaction, _id: connection._id });
    // Handle message responses for both add and remove feed reactions
    async function handleMessage(event) {
      if (event.data.type === "addFeedRection") {
        updateStateAfterReaction(connection, reaction);
      } else if (event.data.type === "removeFeedRection") {
        updateStateAfterReaction(connection, ""); // Reset reaction to empty
      }
    }

    // Helper function to update the state after reaction confirmation
    async function updateStateAfterReaction(connection, newReaction) {
      setConnections((prev) => {
        const arr = [...prev];
        for (let i in arr) {
          if (arr[i]._id == connection._id) {
            // Update the connection's reaction state only after the action has been processed
            arr[i].reaction = newReaction;
            toaster.success(
              `Feed has been ${
                newReaction ? newReaction.toLowerCase() : "reset"
              }.`
            );

            break;
          }
        }
        return arr;
      });
      event.target.classList.contains("active")
        ? event.target.classList.remove("active")
        : event.target.classList.add("active");
    }

    // Clean up the event listener after handling the message
    function cleanupListener() {
      window.removeEventListener("message", handleMessage);
    }

    // Set up the message listener
    window.addEventListener("message", handleMessage);

    // If the reaction matches, remove the reaction
    if (connection.reaction === reaction) {
      window.postMessage(
        {
          action: "removeFeedRection",
          data: {
            activityID: connection.activityID
              .toString()
              .replace("urn:li:activity:", ""),
          },
        },
        "*"
      );
    } else {
      // Otherwise, add the new reaction
      window.postMessage(
        {
          action: "addFeedRection",
          reaction,
          activityID: connection.activityID
            .toString()
            .replace("urn:li:activity:", ""),
        },
        "*"
      );
    }

    // Clean up the listener after the message is handled
    window.addEventListener("message", cleanupListener);
  }

  async function updateFeedCommet({ message, _id }) {
    try {
      const token = localStorage.getItem("jwt");
      let loggedInUser = JSON.parse(localStorage.getItem("linkedInUser"));

      // Verify if token and user data are present
      if (!token || !loggedInUser) {
        throw new Error("Token or loggedInUser missing in localStorage.");
      }

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        liuser: loggedInUser._id,
        authorization: `Bearer ${token}`,
      };

      const req = {
        method: "PUT",
        body: JSON.stringify({ lastComment: message }), // Send only the comment in the body
        headers: new Headers(headers),
      };

      const res = await fetch(`${apiEndPoint}/feed/update/${_id}`, req);
      await fetchData();

      // Ensure the response is successful
      if (!res.ok) {
        throw new Error(
          `Failed to update feed: ${res.status} ${res.statusText}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error("Error updating feed:", error);
      return false;
    }
  }

  const handleSendMessage = async (button, row) => {
    const message = button.target.previousSibling.querySelector("input").value;
    if (!message) return;

    row.lastComment = message;
    // await updateFeedCommet({ message, _id: row._id });

    const handleMessage = async (event) => {
      if (event.data.type === "postCommentOnFeed") {
        updateStateAfterReaction(row);
      }
    };

    const updateStateAfterReaction = (row) => {
      setConnections((prev) => {
        const updatedConnections = [...prev];
        for (let i in updatedConnections) {
          if (updatedConnections[i]._id === row._id) {
            updatedConnections[i] = row;
            break;
          }
        }
        return updatedConnections;
      });
    };

    // Add message listener
    window.addEventListener("message", handleMessage);

    // Send a postMessage to post a comment
    window.postMessage(
      {
        action: "postCommentOnFeed",
        comment: message,
        activityID: row.activityID.replace("urn:li:activity:", ""),
      },
      "*"
    );

    // Clean up listener after the message is processed
    const cleanupListener = () => {
      window.removeEventListener("message", handleMessage);
    };

    window.addEventListener("message", cleanupListener);
  };

  async function updateFeedCommet({ message, _id }) {
    try {
      const token = localStorage.getItem("jwt");
      let loggedInUser = JSON.parse(localStorage.getItem("linkedInUser"));

      // Verify if token and user data are present
      if (!token || !loggedInUser) {
        throw new Error("Token or loggedInUser missing in localStorage.");
      }

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        liuser: loggedInUser._id,
        authorization: `Bearer ${token}`,
      };

      const req = {
        method: "PUT",
        body: JSON.stringify({ lastComment: message }), // Send only the comment in the body
        headers: new Headers(headers),
      };

      const res = await fetch(`${apiEndPoint}/feed/update/${_id}`, req);
      await fetchData();

      // Ensure the response is successful
      if (!res.ok) {
        throw new Error(
          `Failed to update feed: ${res.status} ${res.statusText}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error("Error updating feed:", error);
      return false;
    }
  }

  const handleHideRecord = async (row) => {
    row.isHidden = true;
    try {
      const token = localStorage.getItem("jwt");
      let loggedInUser = JSON.parse(localStorage.getItem("linkedInUser"));

      // Verify if token and user data are present
      if (!token || !loggedInUser) {
        throw new Error("Token or loggedInUser missing in localStorage.");
      }

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        liuser: loggedInUser._id,
        authorization: `Bearer ${token}`,
      };

      const req = {
        method: "PUT",
        body: JSON.stringify({ isHidden: true }), // Send only the comment in the body
        headers: new Headers(headers),
      };

      const res = await fetch(`${apiEndPoint}/feed/update/${row?._id}`, req);
      await fetchData();

      // Ensure the response is successful
      if (!res.ok) {
        throw new Error(
          `Failed to update feed: ${res.status} ${res.statusText}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error("Error updating feed:", error);
      return false;
    }
  };

  return (
    <Pane>
      <Pane
        background="#fff"
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.06)"
        style={{ borderRadius: "5px 5px 5px 5px" }}
        paddingTop={0}
      >
        <div
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "250px auto",
              alignItems: "center",
              gridGap: "30px",
            }}
          >
            <TextInputField
              data-tour="step-5"
              label=""
              name={"search"}
              onChange={handleInputSearch}
              value={inputSearch}
              width={250}
              marginBottom={2}
              placeholder="Search all columns"
            />
          </div>

          <Pane
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Sorting
              sorting={sorting}
              setSorting={setSorting}
              dbSortKey={`selectedSort`}
              sortList={[
                { value: "postDate_desc", label: "Newest first" },
                { value: "postDate_asc", label: "Newest last" },
              ]}
            />
            <Pagination
              pagination={pagination}
              setPagination={setPagination}
              totalResults={totalResults}
            />
          </Pane>
        </div>

        <div className="tableContainer">
          <div className="table">
            {connections.map((row, i) => {
              return (
                <div
                  justifyself="end"
                  key={`${row._id}`}
                  className={`${row.isSent ? "greenBack" : ""}`}
                >
                  <div className="item">
                    <div className="profilePictureCell">
                      <a
                        href={`${
                          (row.publicIdentifier
                            ? "https://www.linkedin.com/in/" +
                              row.publicIdentifier
                            : row.profileUrl.split("?")[0]) || "no-image.png"
                        }`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <img
                          title={`View ${row.postedByName}'s profile`}
                          className="avatar-img"
                          alt=""
                          style={{ float: "left" }}
                          src={`${
                            row.postedByPicture || "no-profile-image.png"
                          }`}
                        />
                      </a>
                    </div>
                    <div className="u-info">
                      <Text className="contact-name">{`${
                        row.postedByName +
                        (row.postedByDegree ? " - " + row.postedByDegree : "")
                      }`}</Text>
                      <br />
                      <Text className="contact-title lime-wrapper">{`${cleanStr(
                        row.postedByHeadline
                      )}`}</Text>
                      <Text className="notification-date">{`${
                        formatTimeStamp(row.postedOn).date
                      }`}</Text>
                      <div className="emotions-section">
                        <div>
                          <svg
                            className={`${
                              row.reaction == "LIKE" ? "active" : ""
                            }`}
                            onClick={(e) => setLikeOption(e, row, "LIKE")}
                            name="LIKE"
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            id="like-creation-large"
                            data-supported-dps="48x48"
                          >
                            <g>
                              <path fill="none" d="M0 0h48v48H0z" />
                              <path
                                d="M25.39 22H10.13c-2 0-3.2 2.11-3.12 3.92a3.23 3.23 0 003.3 3h.57a2.75 2.75 0 00-2.69 2.84 2.84 2.84 0 002.62 2.83 2.83 2.83 0 001.18 5 2.9 2.9 0 00-.29 2 3 3 0 003 2.24h8.18a13.06 13.06 0 003.17-.41l5.13-1.5c.3-.09 4.74 0 6.74 0 3.57-.14 4.53-16.52 0-16.52 0 0-1.65.06-2 0-.16 0-.52-.68-1.41-1.64l-.08-.1c-1.3-1.41-2.78-3.23-3.82-4.26-2.53-2.48-4.63-4.61-6.09-7.84C23.8 9.83 23.71 9 22 9a2.77 2.77 0 00-2.38 2.9 27.06 27.06 0 00.38 2.85c.63 3.38 3.43 7.08 3.51 7.25"
                                fill="#378fe9"
                              />
                              <path
                                d="M23.49 23H10.13c-2 0-3.21 1.19-3.13 3a3.22 3.22 0 003.31 3h.57a2.77 2.77 0 00-2.69 2.84 2.84 2.84 0 002.62 2.83v0a2.83 2.83 0 001.18 5 2.9 2.9 0 00-.29 2 3 3 0 003 2.24h8.18a13.06 13.06 0 003.17-.41l5.13-1.5c.3-.09 4.74 0 6.74 0 3.57-.14 4.53-16.52 0-16.52 0 0-1.65.06-2 0-.16 0-.52-.68-1.41-1.64l-.08-.1c-1.3-1.41-2.78-3.23-3.82-4.26-2.53-2.48-4.63-4.6-6.09-7.84C23.8 9.87 23.71 9 22 9a2.66 2.66 0 00-2.38 2.9 27.06 27.06 0 00.38 2.85c.63 3.38 3.43 8 3.51 8.21z"
                                fill="none"
                                stroke="#004182"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                          <svg
                            className={`${
                              row.reaction == "PRAISE" ? "active" : ""
                            }`}
                            onClick={(e) => setLikeOption(e, row, "PRAISE")}
                            name="PRAISE"
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            id="praise-creation-large"
                            data-supported-dps="48x48"
                          >
                            <g>
                              <path fill="none" d="M0 0h48v48H0z" />
                              <path
                                d="M39.33 30.67l-1.6-1.2s-.66-6.35-1.82-7.58c-2.09-2.2-3.42-5.42-4.3-8.46-.55-1.88-.94-2.55-2.63-2.58a2.57 2.57 0 00-2.31 2.84 21.76 21.76 0 00.23 2.55 34.79 34.79 0 002 6.54l-.65-.39-15.44-11.74c-1.15-.87-2.81-1.26-3.93.24s-.22 2.89.93 3.75l8 6 2.39 1.81-12.79-9.61c-1.15-.87-2.81-1.26-3.94.24s-.21 2.92.93 3.75l8 6 4.8 3.6-10.4-7.79c-1.15-.87-2.81-1.26-3.94.24s-.21 2.89.94 3.75l8 6 4 3-8-6c-1.15-.87-2.78-1.29-3.86.14s-.29 3 .86 3.86l14.54 11a9.77 9.77 0 007.41 1.8c.81.59 2.4 1.8 2.4 1.8a27.46 27.46 0 005.59-5.8 41 41 0 004.59-7.76z"
                                fill="#49992a"
                              />
                              <path
                                d="M29.73 23.48l-2.41-1.81"
                                fill="#93d870"
                              />
                              <path
                                d="M39.21 31l.12-.28"
                                fill="none"
                                stroke="#43722b"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M37.6 28.4c-.25-1.87-.41-7.47-1.27-8.38-2.09-2.21-3.84-3.55-4.72-6.59-.55-1.88-.94-2.55-2.63-2.58a2.57 2.57 0 00-2.31 2.84 21.76 21.76 0 00.23 2.55 43.79 43.79 0 002 6.92L12.81 11c-1.15-.86-2.81-1.25-3.93.25s-.22 2.88.93 3.75l8 6 2.39 1.8-12.79-9.59c-1.15-.86-2.81-1.25-3.94.25s-.21 2.88.93 3.75l8 6 4.8 3.61L6.8 19c-1.15-.86-2.81-1.25-3.94.25s-.21 2.89.94 3.75l8 6 4 3-8-6c-1.15-.86-2.78-1.29-3.86.15s-.29 3 .86 3.85l14.53 10.68a9.77 9.77 0 007.41 1.8"
                                fill="none"
                                stroke="#165209"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29.73 23.48l-2.41-1.81"
                                fill="none"
                                stroke="#43722b"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M45.93 31.87l-1.6-1.2s-.66-6.36-1.82-7.58a21 21 0 01-4.3-8.46c-.55-1.88-.94-2.55-2.63-2.58a2.56 2.56 0 00-2.31 2.83 21.76 21.76 0 00.23 2.55c.63 3.3 1.31 6 1.39 6.16l-15.24-11.4c-1.15-.87-2.81-1.26-3.94.24s-.22 2.89.93 3.75l8 6L27 24l-12.76-9.62c-1.15-.86-2.81-1.25-3.93.25s-.22 2.88.93 3.75l8 6L24 28l-10.36-7.82c-1.15-.87-2.82-1.25-3.94.24s-.22 2.89.93 3.76l8 6 4 3-8-6c-1.15-.86-2.79-1.29-3.87.15s-.29 3 .86 3.85l14.31 10.7c2.53 1.87 7.07 2.12 7 2.12a21.45 21.45 0 002.81 1.47A11.38 11.38 0 0042.93 41a17 17 0 003-9.13z"
                                fill="#6dae4f"
                              />
                              <path d="M36.33 24.67l-2.41-1.8" fill="#93d870" />
                              <path
                                d="M12 1.65l.79 3.52M23.2 6l-2.94 2M18.68 1l-2.15 5.6M45.77 31.88a7.5 7.5 0 01-1.62-2.66c-.18-.75-.23-1.53-.37-2.29-.22-1.17-.41-2.93-1.27-3.84a21 21 0 01-4.3-8.46c-.55-1.88-.94-2.55-2.63-2.58a2.56 2.56 0 00-2.31 2.83 21.76 21.76 0 00.23 2.55c.63 3.3 1.31 6 1.39 6.16l-15.24-11.4c-1.15-.87-2.81-1.26-3.94.24s-.22 2.89.93 3.75l8 6L27 24l-12.76-9.62c-1.15-.86-2.81-1.25-3.93.25s-.22 2.88.93 3.75l8 6L24 28l-10.36-7.82c-1.15-.87-2.82-1.25-3.94.24s-.22 2.89.93 3.76l8 6 4 3-8-6c-1.15-.86-2.79-1.29-3.87.15s-.29 3 .86 3.85l14.31 10.7a9.65 9.65 0 004 1.75c.64.12 1.32.18 2.07.32a9.93 9.93 0 013.08 1 3 3 0 002.15.17 13.23 13.23 0 006.26-4.59 12.75 12.75 0 002.48-7 2.92 2.92 0 00-.2-1.65z"
                                fill="none"
                                stroke="#165209"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                          <svg
                            className={`${
                              row.reaction == "APPRECIATION" ? "active" : ""
                            }`}
                            onClick={(e) =>
                              setLikeOption(e, row, "APPRECIATION")
                            }
                            name="APPRECIATION"
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            id="support-creation-large"
                            data-supported-dps="48x48"
                          >
                            <path
                              d="M14 32h-.69c-.79-.29-4.82-1.83-7.53-2.59a.86.86 0 01-.63-.73 2.4 2.4 0 01.37-1.77 1.4 1.4 0 011.19-.58 2.06 2.06 0 01.7.12 5.71 5.71 0 011.49.87l.19.15 1.25.87c.46.33.87.61 1.16.83l2.92 1.19c.39.18 2 .92 2 1.34s-1.86.37-2.25.38z"
                              fill="#9983b1"
                            />
                            <path
                              d="M42.75 44.52a74.76 74.76 0 01-11.84-1.23h-.21a67.24 67.24 0 01-8.77-1.82c-2.56-.77-5.08-1.77-7.52-2.73l-1.09-.43c-2.31-.9-4.19-1.66-5.9-2.39l-.64-.27a24.09 24.09 0 01-3-1.41c-1.33-.78-1.74-1.78-1.19-3v-.09a1.93 1.93 0 012-1.15h.11a5.61 5.61 0 01.58 0c2.12.23 8.27 2.57 9.48 3l4 .14h.1l9 .31c-.7-.64-2.45-1.75-6.75-2.86-.82-.21-1.56-.45-1.73-1a2.38 2.38 0 01.78-2.54 3.18 3.18 0 011.84-.42 9.74 9.74 0 012.28.27c.46.12.83.24 1.19.35a13.5 13.5 0 002.88.65A31 31 0 0133 29c5 1.36 6 3.87 6.59 5.38-.19-.62 0-.16.1-.39l.14-.2h.77c.86 0 2.5-.09 2.51-.09a.85.85 0 01.85.63c1.71 6.23-.64 9.69-.74 9.83a.66.66 0 01-.48.33z"
                              fill="#bba9d1"
                            />
                            <path
                              d="M14.63 11.35a5 5 0 00-7.13-.09l-.09.09a5.24 5.24 0 000 7.34l7.79 7.9 7.8-7.9a5.24 5.24 0 000-7.34 5.06 5.06 0 00-3.61-1.53 5 5 0 00-3.6 1.53l-.57.58z"
                              fill="#df704d"
                              fillRule="evenodd"
                            />
                            <path
                              d="M43.65 34.32a7.14 7.14 0 011.48 5 10.7 10.7 0 01-2.28 5.29c-.41.4-15.19-1.48-18.65-2.16S4.05 34.43 3.41 34s-.7-3.12 1.13-3.69 5.86 2.15 9.53 2.75a105.12 105.12 0 0012.68.94c2.48 0-2.68-2.16-3.9-2.64s-3.2-.87-3.61-2.16.92-2.24 2-2.24a30.41 30.41 0 015.89 1.48A19.6 19.6 0 0135 29.83a9.16 9.16 0 014.67 4.49c.33.68 3.62-.32 3.98 0z"
                              fill="none"
                              stroke="#493d57"
                              strokeWidth="2"
                            />
                            <path
                              d="M5.47 30.17c-.93-2 .22-3.17 1.58-3.17s2.4 1.48 4.37 2.83a74.82 74.82 0 006.89 3.87"
                              fill="none"
                              stroke="#493d57"
                              strokeWidth="2"
                            />
                            <path
                              d="M14.36 10.63a5.48 5.48 0 00-7.74.06 5.63 5.63 0 00-.08 7.83L15 27l8.43-8.49a5.62 5.62 0 001.52-4.86 5.54 5.54 0 00-3-4.08A5.22 5.22 0 0019.49 9a5.48 5.48 0 00-3.88 1.65l-.64.61z"
                              fill="none"
                              stroke="#77280c"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path fill="none" d="M0 0h48v48H0z" />
                          </svg>
                          <svg
                            className={`${
                              row.reaction == "EMPATHY" ? "active" : ""
                            }`}
                            onClick={(e) => setLikeOption(e, row, "EMPATHY")}
                            name="EMPATHY"
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            id="empathy-creation-large"
                            data-supported-dps="48x48"
                          >
                            <g>
                              <path fill="none" d="M0 0h48v48H0z" />
                              <path
                                d="M22.9 14.9a9.91 9.91 0 10-14 14L24 44l15.1-15.07a10 10 0 00-2.67-16 9.55 9.55 0 00-4.33-1 9.84 9.84 0 00-7 2.93L24 16z"
                                fill="#df704d"
                                fillRule="evenodd"
                              />
                              <path
                                d="M22.9 14.88a9.88 9.88 0 00-14 13.94L24 43.8l15.1-15A9.86 9.86 0 0036.43 13a9.55 9.55 0 00-4.33-1h0a9.83 9.83 0 00-7 2.92L24 16z"
                                fill="none"
                                stroke="#77280c"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                          <svg
                            className={`${
                              row.reaction == "INTEREST" ? "active" : ""
                            }`}
                            onClick={(e) => setLikeOption(e, row, "INTEREST")}
                            name="INTEREST"
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            id="interest-creation-large"
                            data-supported-dps="48x48"
                          >
                            <g>
                              <path fill="none" d="M0 0h48v48H0z" />
                              <path
                                d="M27 44h-6a2 2 0 01-2-2v-5h10v5a2 2 0 01-2 2z"
                                fill="#c07800"
                              />
                              <path
                                d="M21 37l8 .07a11.25 11.25 0 01.09-3.57 6.61 6.61 0 01.78-1.63 5.16 5.16 0 01.63-.69l.14-.12A11.75 11.75 0 0035 22.17a11 11 0 00-22 0v.06a11.43 11.43 0 004.13 8.66 10.73 10.73 0 011.77 2.79 9.47 9.47 0 01.8 3.08l.3.24"
                                fill="#f5bb5c"
                              />
                              <path
                                d="M12 5.82L14.42 9M36 5.82L33.58 9M24 2v4.77"
                                fill="none"
                                stroke="#5d3b01"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.91"
                              />
                              <path
                                d="M27 44h-6a2.21 2.21 0 01-2-2.1V37h10v4.9a2.21 2.21 0 01-2 2.1z"
                                fill="none"
                                stroke="#5d3b01"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29 38c0-3.63.41-4.47.41-4.47a6.34 6.34 0 011.89-3 12.84 12.84 0 001.28-1.38 11.86 11.86 0 001.81-3.24 11.39 11.39 0 00.65-3.7v-.06A11.1 11.1 0 0024 11h0a11.09 11.09 0 00-11 11.17v.06a11.39 11.39 0 00.65 3.7 11.86 11.86 0 001.81 3.24 12.84 12.84 0 001.28 1.38 6.34 6.34 0 011.89 3s.37.84.37 4.45"
                                fill="none"
                                stroke="#5d3b01"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                          <svg
                            className={`${
                              row.reaction == "MAYBE" ? "active" : ""
                            }`}
                            onClick={(e) => setLikeOption(e, row, "MAYBE")}
                            name="MAYBE"
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            id="maybe-creation-large"
                            data-supported-dps="48x48"
                          >
                            <g>
                              <path fill="none" d="M0 0h48v48H0z" />
                              <circle
                                cx="24.5"
                                cy="24.5"
                                r="16.5"
                                fill="#deb8dc"
                                stroke="#523951"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <path
                                d="M15 33.66l11.48-1.92a1.9 1.9 0 012.2 1.58 2 2 0 01-1.56 2.18l-4.81.81a1.53 1.53 0 011.17.29 1.55 1.55 0 01.62 1 1.63 1.63 0 01-1.22 1.84 1.58 1.58 0 01.84 1.16 1.68 1.68 0 01-1 1.82 1.57 1.57 0 01.28 1.16 1.74 1.74 0 01-1.5 1.54l-.56.12q-2.18.46-4.38.72a27.87 27.87 0 01-5-.39 3.31 3.31 0 01-2.43-2.15c-1.09-2.27-1.69-6.56-.35-7.8a4.31 4.31 0 001.75-2.4c.64-1.47 1.31-3.16 1.82-4.6s.71-2.09 1.66-2.44a1.94 1.94 0 012.34 1.11 8 8 0 01-.14 3c-.24 2-.25 2.46 0 3.14"
                                fill="#deb8dc"
                              />
                              <path
                                d="M26.48 31.74a1.9 1.9 0 012.2 1.58 2 2 0 01-1.56 2.18l-4.81.81a1.53 1.53 0 011.17.29 1.55 1.55 0 01.62 1 1.63 1.63 0 01-1.22 1.84 1.58 1.58 0 01.84 1.16 1.68 1.68 0 01-1 1.82 1.57 1.57 0 01.28 1.16 1.74 1.74 0 01-1.5 1.54l-.56.12q-2.18.46-4.38.72a27.87 27.87 0 01-5-.39 3.31 3.31 0 01-2.43-2.15c-1.09-2.27-1.69-6.56-.35-7.8a4.31 4.31 0 001.75-2.4c.64-1.47 1.31-3.16 1.82-4.6s.71-2.09 1.66-2.44a1.94 1.94 0 012.34 1.11 8 8 0 01-.14 3c-.24 2-.25 2.46 0 3.14zM16 14a4.19 4.19 0 012.75-1.23 4.18 4.18 0 012.94.67M22 27h3"
                                fill="none"
                                stroke="#523951"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle cx="18.5" cy="20" r="2" fill="#523951" />
                              <circle cx="30" cy="22" r="2" fill="#523951" />
                              <path
                                d="M29 16.68a4.19 4.19 0 013 0 4.2 4.2 0 012.45 1.71"
                                fill="none"
                                stroke="#523951"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="u-msg">
                      <div className={`flex`}>
                        <Tooltip content="View post on LinkedIn">
                          <a
                            className="text-none"
                            href={`${row.postUrl}`}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <img
                              className={`li-icon`}
                              // src={ chrome.runtime.getURL('linkedin-logo-icon.svg')}
                              src="linkedin-logo-icon.svg"
                              alt="linkedin"
                            />
                          </a>
                        </Tooltip>
                        <Text className="headline lime-wrapper">
                          {ReactHtmlParser(
                            replaceTextToLink(
                              limeTextConvertor(
                                row.postContent.replaceAll("&amp;", "&")
                              )
                            )
                          )}
                        </Text>
                      </div>
                      <Text className="actions-section">
                        <TextInputField
                          style={{ flexGrow: 1 }}
                          label=""
                          defaultValue={`${row.lastComment || inputs[i] || ""}`}
                          className={`${row.isSent ? "disable-input" : ""}`}
                          // onChange={handleChange}
                          name={inputs[i]}
                          onChange={(e) => handleChange(e, i)}
                          readOnly={!!row.isSent}
                        />

                        {!row.isSent ? (
                          <Button
                            appearance="primary"
                            onClick={(e) => handleSendMessage(e, row)}
                          >
                            {`${row.action}`}
                          </Button>
                        ) : (
                          ""
                        )}
                        <Tooltip content="Hide Record">
                          <IconButton
                            onClick={() => handleHideRecord(row)}
                            // need to add function to hide record
                            icon={EyeOffIcon}
                            color="muted"
                            marginLeft={10}
                          ></IconButton>
                        </Tooltip>
                      </Text>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Pane>
    </Pane>
  );
});

export default Feed;
