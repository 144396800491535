import React, { useState, useRef, useEffect } from "react";
import {
  Pane,
  Button,
  Text,
  Image,
  TextInputField,
  toaster,
  Dialog,
} from "evergreen-ui";
import axios from "axios";
import logo from "../../assets/img/icon.svg";
import { apiEndPoint } from "../../utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function Login() {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(() => {
    return JSON.parse(localStorage.getItem("isExtensionInstalled")) || false;
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const componentMounted = useRef(true);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [emailValue, setEmailValue] = useState({ email: "" });
  const [otpValue, setOtpValue] = useState({ otp: "" });
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOtpFields, setShowOtpFields] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const navigate = useNavigate();

  // Check if extension is installed and set up messaging listener
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type === "getCsrfToken") {
        const tokenExists = !!event.data.csrfToken;
        setIsExtensionInstalled(true);
        localStorage.setItem("isExtensionInstalled", tokenExists);
        setError(event.data.error || null);
      }
    };

    window.addEventListener("message", handleMessage);
    window.onload = () => window.postMessage({ type: "getCsrfToken" }, "*");

    return () => window.removeEventListener("message", handleMessage);
  }, []);

  // Handle input changes for login form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  // Handle login submission
  const handleLogin = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        apiEndPoint + "/auth/login",
        credentials
      );
      if (data.jwt) {
        localStorage.setItem("jwt", data.jwt);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("user", JSON.stringify(data.user));
        Cookies.set("jwt", data.jwt);
        Cookies.set("userId", data.userId);

        navigate(
          data.user.subscriptionStatus === "active" ? "/welcome" : "/package"
        );
      } else {
        toaster.danger(data.message);
      }
    } catch (e) {
      toaster.danger(e.response?.data?.message || e.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle password reset link submission
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await axios.patch(
        apiEndPoint + "/auth/forgot-password-link",
        emailValue
      );
      if (data.success) {
        toaster.success("Reset password link has been sent to your email");
        localStorage.setItem("userId", data.userId);
        Cookies.set("userId", data.userId);
        setOtpSent(true);
        setShowPasswordDialog(false);
        setShowOtpFields(true);
      } else {
        toaster.danger(data.message);
      }
    } catch (e) {
      toaster.danger(e.response?.data?.message || e.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP resend
  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const res = await fetch(apiEndPoint + "/auth/resend-otp", {
        method: "POST",
        body: JSON.stringify({
          userId: localStorage.getItem("userId"),
          forOTP: "signup",
        }),
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      });
      const data = await res.json();
      toaster.success("OTP has been resent to your email.");
    } catch (error) {
      toaster.danger(
        error?.message || "Unable to resend OTP, please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  // Handle password reset confirmation
  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      return toaster.danger("Passwords do not match.");
    }
    setLoading(true);
    try {
      const { data } = await axios.post(apiEndPoint + "/auth/reset-password", {
        userId: localStorage.getItem("userId"),
        password: newPassword,
        confirmPassword,
        otp: otpValue.otp,
      });
      toaster.success("Password reset successfully.");
      setOtpSent(false);
      setShowOtpFields(false);
    } catch (e) {
      toaster.danger(e.response?.data?.message || e.message);
    } finally {
      setLoading(false);
    }
  };

  // Render the login component
  return (
    <Pane
      display="grid"
      justifyItems="center"
      textAlign="center"
      gridGap={20}
      placeContent="center"
      height="100vh"
    >
      <Image width={80} src={logo} alt="logo" />

      {!otpSent && !showOtpFields ? (
        <>
          <Text size={600} width={600}>
            Please login to view your dashboard.
          </Text>
          <Pane width="60%" display="block" textAlign="left">
            <TextInputField
              label="Email"
              name="email"
              onChange={handleChange}
              placeholder="enter your email"
            />
            <TextInputField
              label="Password"
              name="password"
              onChange={handleChange}
              type="password"
              placeholder="enter your password"
            />
            <Button
              appearance="primary"
              size="large"
              onClick={handleLogin}
              isLoading={loading}
              position="relative"
              left="50%"
              transform="translateX(-50%)"
            >
              Login
            </Button>
          </Pane>
        </>
      ) : (
        <>
          <Text size={600} width={600}>
            Please enter OTP and set your new password.
          </Text>
          <Pane width="60%" display="block" textAlign="left">
            <TextInputField
              label="Enter OTP"
              onChange={(e) => setOtpValue({ otp: e.target.value })}
              placeholder="enter the OTP"
            />
            <TextInputField
              label="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              placeholder="enter your new password"
            />
            <TextInputField
              label="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              placeholder="confirm your new password"
            />
            <Pane
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={10}
            >
              <Button
                appearance="primary"
                marginRight={10}
                size="large"
                onClick={handlePasswordChange}
                isLoading={loading}
              >
                Reset Password
              </Button>
              <Button
                appearance="primary"
                size="large"
                onClick={handleResendOtp}
              >
                Resend OTP
              </Button>
            </Pane>
          </Pane>
        </>
      )}

      <Pane marginTop={10} cursor="pointer">
        <Dialog
          isShown={showPasswordDialog}
          title="Forgot Password"
          confirmLabel="Reset Password"
          onCloseComplete={() => setShowPasswordDialog(false)}
          onConfirm={handleSubmit}
        >
          <TextInputField
            label="Email"
            value={emailValue.email}
            onChange={(e) =>
              setEmailValue((prev) => ({ ...prev, email: e.target.value }))
            }
          />
        </Dialog>

        {!otpSent && !showOtpFields ? (
          <center>
            <Text onClick={() => setShowPasswordDialog(true)}>
              Forgot password?
            </Text>
          </center>
        ) : (
          <center>
            <Text
              onClick={() => {
                setOtpSent(false);
                setShowOtpFields(false);
              }}
            >
              Login?
            </Text>
          </center>
        )}
        <Pane
          marginTop={10}
          cursor="pointer"
          onClick={() => navigate("/signup")}
        >
          <Text>
            <p className="line">
              <span>or</span>
            </p>
          </Text>
          <Text>Create Account</Text>
        </Pane>
      </Pane>
    </Pane>
  );
}

export default Login;
