import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  Pane,
  Tooltip,
  Text,
  Button,
  IconButton,
  TextInputField,
  CalendarIcon,
  StarEmptyIcon,
  BriefcaseIcon,
  TrendingUpIcon,
  AddLocationIcon,
  EyeOffIcon,
  SelectMenu,
  toaster,
} from "evergreen-ui";
import { debounce } from "debounce";
import axios from "axios";
import "./Notifications.css";
import Pagination from "./components/Pagination";
import Sorting from "./components/Sorting";
import {
  formatTimeStamp,
  cleanStr,
  limeTextConvertor,
  readStorage,
  apiEndPoint,
} from "../../../utils";
import { useSearchParams } from "react-router-dom";
/**
 * @desc React component
 * @param {Object} Props
 * @returns Table component
 */
let isSearchPressed = false;
const Table = forwardRef((props, ref) => {
  // STATES
  useImperativeHandle(ref, () => ({
    refreshData() {
      if (userId?._id) {
        fetchData();
      }
    },
  }));
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({
    count: 25,
    currentPage: 1,
  });

  let token = localStorage.getItem("jwt");

  let loggedInUser = localStorage.getItem("user");
  loggedInUser = JSON.parse(loggedInUser);

  const user = localStorage.getItem("profile");
  const userId = JSON.parse(user) || null;
  const [totalResults, setTotalResults] = useState(0);
  const [sorting, setSorting] = useState("publishedAt_desc");

  const [inputSearch, setInputSearch] = useState(props.searchValue);
  const [fetchSearch, setFetchSearch] = useState(props.searchValue);
  const [linkedInUser, setLinkedInUser] = useState(true);
  // TODO
  const [fetchLoading, setFetchLoading] = useState([]);
  const [inputs, setInputsState] = useState([]);
  const [mySettings, setMySettings] = useState({
    position: "",
    promotion: "",
    birthday: "",
    workAnniversary: "",
  });
  const [options] = useState(
    [
      { label: "Anniversaries", value: "WORK_ANNIVERSARY_PROP" },
      { label: "Birthdays", value: "BIRTHDAY_PROP" },
      { label: "Job Changes", value: "JOB_CHANGE_PROP" },
    ].map((data) => data)
  );

  const [selectedItemsState, setSelectedItems] = useState(
    JSON.parse(localStorage.getItem("selectedItemsState")) || []
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") || null;
  useEffect(() => {
    // Get a specific query parameter

    if (search != null) {
      setInputSearch(search);
    }

    // Remove a query parameter
    setSearchParams((params) => {
      params.delete("search");
      return params;
    });
  }, []);

  const [selectedItemNamesState, setSelectedItemNames] = useState(null);
  // -----
  const componentMounted = useRef(true);
  /**
   * @desc Send a fetch req to the server to get back notifications
   * @param {Number} [start] The start index of the results for pagination
   * @param {Number} [count] The no. of results per page for pagination
   * @param {String} [sortBy] the field to sort in
   * @param {String} [sortOrder] the order to sort in
   * @param {Array} [searchIn] the fields for text search
   * @param {Array} [search] the texts to search per field
   * @return {Object} - notifications data from the server as per the filters
   */

  async function fetchNotifications(
    start,
    count,
    sortBy,
    sortOrder,
    fetchSearch,
    selectedItemsState
  ) {
    let searchUrl = ``;
    if (fetchSearch !== "") {
      searchUrl = `&search=${fetchSearch}`;
    }
    if (selectedItemsState.length > 0) {
      searchUrl += `&filterOn=${selectedItemsState.join(",")}`;
    }
    if (inputSearch) {
      searchUrl = `&search=${inputSearch}`;
    }

    const { data } = await axios.get(
      `${apiEndPoint}/notifications?start=${start}&count=${count}&sortBy=${sortBy}&sortOrder=${sortOrder}${
        searchUrl && searchUrl !== "&search=undefined" ? `&${searchUrl}` : ""
      }`,
      {
        headers: {
          liuser: userId?._id,
          authorization: "Bearer " + token,
        },
      }
    );

    let response = await axios.get(
      `${apiEndPoint}/notifications/get-message-settings`,
      {
        headers: { authorization: "Bearer " + token },
      }
    );
    setLoading(false);
    if (response.data.success) {
      setMySettings((prev) => ({
        ...prev,
        ...response.data.data,
      }));
    }
    return data;
  }
  async function loadSettings() {
    const { data } = await axios.get(
      `${apiEndPoint}/notifications/get-message-settings`,
      {
        headers: { authorization: "Bearer " + token },
      }
    );
    setLoading(false);
    if (data.success) {
      setMySettings((prev) => ({
        ...prev,
        ...data.data,
      }));
      //toaster.success('Your password has been updated!', { duration: 6 });
      return;
    }

    toaster.danger(data.message, { duration: 6 });
  }
  // -----

  /**
   * @desc Calls fetchNotifications and sets the results to component states
   */

  const fetchData = async () => {
    //setLoading(true);
    const { data, meta } = await fetchNotifications(
      pagination.count * (isSearchPressed ? 0 : pagination.currentPage - 1),
      pagination.count,
      sorting.split("_")[0],
      sorting.split("_")[1] === "asc" ? 1 : -1,
      fetchSearch,
      selectedItemsState
    );
    isSearchPressed = false;
    const notificationsArr = data.notifications;
    setTotalResults(meta.totalResults);
    setNotifications(notificationsArr);
    setFetchLoading(notificationsArr.map((cur) => false));
    setLoading(false);
  };

  // USE EFFECTS

  // runs when component mounts

  // runs when any of the following state changes
  useEffect(() => {
    if (userId?._id) {
      fetchData();
    }
  }, [pagination, sorting, selectedItemsState, fetchSearch, inputSearch]);
  useEffect(() => {
    let items =
      localStorage.getItem("selectedItemsState") || JSON.stringify([]);
    items = JSON.parse(items);
    const selectedItemsLength = items.length;
    let selectedNames = "";
    if (selectedItemsLength === 0) {
      selectedNames = "";
    } else {
      selectedNames = selectedItemsLength.toString() + " selected...";
    }
    setSelectedItemNames(selectedNames);
    return () => {
      componentMounted.current = false;
    };
  }, []);
  // HANDLERS

  /**
   * @desc Sends Message to bckg to collect complete data of the current row connection and updates the server and current state accordingly
   * @param {Object} [cell] - The cell object passed in table column
   */

  const handleFetchSearch = (e) => {
    const { value } = e.target;
    isSearchPressed = true;
    setFetchSearch(value);
  };

  const handler = useCallback(debounce(handleFetchSearch, 300), []);

  const handleInputSearch = (e) => {
    const { value } = e.target;
    setInputSearch(value);
    isSearchPressed = true;
    handler(e);
  };
  function handleChange(e, i) {
    const { name, value } = e.target;
    setInputsState((prev) => {
      const arr = [...prev];
      arr[i] = value;
      return arr;
    });
  }
  const handleHideRecord = async (row) => {
    row.isHidden = true;

    const { data: result } = await axios.patch(
      `${apiEndPoint}/notifications/update_status/${row._id}`,
      { row: row },
      { headers: { authorization: "Bearer " + token } }
    );
    const { data } = result;
    if (userId?._id) {
      fetchData();
    }
  };

  const handleSendMessage = async (button, row) => {
    row.linkedInUserProfileId = userId?.profileId;

    let message = button.target.previousSibling
      .querySelector("input")
      .value.trim();
    if (!message) {
      return toaster.danger(
        "Please enter a message in the box before hitting the Message button.",
        {
          duration: 6,
        }
      );
    }
    row.message = message;

    // Sending message to the content script using window.postMessage
    window.postMessage(
      {
        action: "sendFeedMessage",
        data: row,
      },
      "*"
    );
    window.addEventListener("message", handleMessage);
    // Listening for response from content script

    async function handleMessage(event) {
      if (event.data.type === "sendFeedMessage") {
        const resp = event.data.data;

        if (resp?.included?.length) {
          toaster.success("Message sent!", {
            duration: 6,
          });
          row.isSent = true;
          row.actionsType = "CONFIRMATION";
          const { data: result } = await axios.patch(
            `${apiEndPoint}/notifications/update/${row._id}`,
            { row: row },
            { headers: { authorization: "Bearer " + token } }
          );
          const { data } = result;
          setNotifications((prev) => {
            const arr = [...prev];
            for (let i in arr) {
              if (arr[i]._id == row._id) {
                arr[i] = data.update;
                break;
              }
            }
            return arr;
          });
        } else {
          toaster.danger("Something went wrong!", {
            duration: 6,
          });
        }
        // Clean up event listener after receiving the response
        return () => window.removeEventListener("message", handleMessage);
      }
    } // Ensures the listener is removed after one response
  };

  return (
    <Pane>
      <Pane
        background="#fff"
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.06)"
        style={{ borderRadius: "5px 5px 5px 5px" }}
        paddingTop={0}
      >
        <div
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "250px auto",
              alignItems: "end",
              gridGap: "30px",
            }}
          >
            {/* <Sorting sorting={sorting} setSorting={setSorting} /> */}
            <TextInputField
              label=""
              name={"search"}
              onChange={handleInputSearch}
              value={inputSearch}
              width={250}
              marginBottom={2}
              placeholder="Search all columns"
            />
            <SelectMenu
              isMultiSelect
              title="Select multiple names"
              options={options}
              selected={selectedItemsState}
              onSelect={(item) => {
                const selected = [...selectedItemsState, item.value];
                const selectedItems = selected;
                const selectedItemsLength = selectedItems.length;
                let selectedNames = "";
                if (selectedItemsLength === 0) {
                  selectedNames = "";
                } else {
                  selectedNames =
                    selectedItemsLength.toString() + " selected...";
                }
                setPagination((prev) => ({
                  ...prev,
                  count: 25,
                  currentPage: 1,
                }));
                setSelectedItems(selectedItems);
                localStorage.setItem(
                  "selectedItemsState",
                  JSON.stringify(selectedItems)
                );
                //chrome.storage.local.set({ selectedItemsState: selectedItems })
                setSelectedItemNames(selectedNames);
              }}
              onDeselect={(item) => {
                const deselectedItemIndex = selectedItemsState.indexOf(
                  item.value
                );
                const selectedItems = selectedItemsState.filter(
                  (_item, i) => i !== deselectedItemIndex
                );
                const selectedItemsLength = selectedItems.length;
                let selectedNames = "";
                if (selectedItemsLength === 0) {
                  selectedNames = "";
                } else {
                  selectedNames =
                    selectedItemsLength.toString() + " selected...";
                }
                setPagination((prev) => ({
                  ...prev,
                  count: 25,
                  currentPage: 1,
                }));
                setSelectedItems(selectedItems);
                localStorage.setItem(
                  "selectedItemsState",
                  JSON.stringify(selectedItems)
                );
                //chrome.storage.local.set({ selectedItemsState: selectedItems })
                setSelectedItemNames(selectedNames);
              }}
            >
              <Button style={{ width: "100px" }}>
                {selectedItemNamesState || "Filter On..."}
              </Button>
            </SelectMenu>
          </div>
          <Pane
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Sorting
              sorting={sorting}
              setSorting={setSorting}
              dbSortKey={`selectedSort`}
              sortList={[
                { value: "publishedAt_desc", label: "Newest first" },
                { value: "publishedAt_asc", label: "Newest last" },
              ]}
            />
            <Pagination
              pagination={pagination}
              setPagination={setPagination}
              totalResults={totalResults}
              // handleDownload={handleDownload}
            />
          </Pane>
        </div>
        <div className="tableContainer">
          <div className="table">
            {notifications.map((row, i) => {
              return (
                <div
                  justifyself="end"
                  key={`${row._id}`}
                  className={`${row.isSent ? "greenBack" : ""}`}
                >
                  <div className="item">
                    <div className="profilePictureCell">
                      <a
                        href={`${
                          row.publicIdentifier
                            ? "https://www.linkedin.com/in/" +
                              row.publicIdentifier
                            : "no-image.png"
                        }`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <img
                          title={`${row.subActionText}`}
                          className="avatar-img"
                          alt=""
                          style={{ float: "left" }}
                          src={`${
                            row.profilePicture || "no-profile-image.png"
                          }`}
                        />
                      </a>
                    </div>
                    <div className="u-info">
                      <Text className="contact-name">{`${cleanStr(
                        row.firstName + " " + row.lastName
                      )}`}</Text>
                      <br />
                      <Text className="contact-title lime-wrapper">{`${cleanStr(
                        row.occupation
                      )}`}</Text>
                      <Text className="notification-date">{`${
                        formatTimeStamp(row.publishedAt).date
                      }`}</Text>
                    </div>
                    <div className="u-msg">
                      <div className="flex notif-icon">
                        {row.type == "WORK_ANNIVERSARY_PROP" ? (
                          <CalendarIcon color="muted"></CalendarIcon>
                        ) : (
                          ""
                        )}
                        {row.type == "BIRTHDAY_PROP" ? (
                          <StarEmptyIcon color="muted"></StarEmptyIcon>
                        ) : (
                          ""
                        )}
                        {/* {row.type=="JOB_CHANGE_PROP" ? (<BriefcaseIcon></BriefcaseIcon>):''} */}
                        {row.headline.includes("being promoted to") ? (
                          <TrendingUpIcon color="muted"></TrendingUpIcon>
                        ) : (
                          ""
                        )}
                        {row.headline.includes("starting a new position") ||
                        row.headline.includes("on starting as") ? (
                          <BriefcaseIcon color="muted"></BriefcaseIcon>
                        ) : (
                          ""
                        )}
                        {row.headline.includes("for starting as") ? (
                          <BriefcaseIcon color="muted"></BriefcaseIcon>
                        ) : (
                          ""
                        )}
                        {row.headline.includes(
                          "starting an additional position"
                        ) ? (
                          <AddLocationIcon color="muted"></AddLocationIcon>
                        ) : (
                          ""
                        )}
                        <Text className="headline lime-wrapper">{`${limeTextConvertor(
                          row.headline.replaceAll("&amp;", "&")
                        )}`}</Text>
                      </div>
                      <Text className="actions-section">
                        <TextInputField
                          label={``}
                          defaultValue={`${
                            (row.type == "BIRTHDAY_PROP" &&
                              mySettings?.birthday) ||
                            (row.type == "WORK_ANNIVERSARY_PROP" &&
                              mySettings?.workAnniversary) ||
                            (row.type == "JOB_CHANGE_PROP" &&
                              row?.subType == "newco" &&
                              mySettings?.position) ||
                            (row.type == "JOB_CHANGE_PROP" &&
                              !row?.subType &&
                              mySettings?.promotion) ||
                            row.message ||
                            row.prefilledMessage ||
                            (decodeURIComponent(row.messageAction).match(
                              /\/body\/(.+)\?/
                            ) || ["", ""])[1] ||
                            inputs[i] ||
                            row.messageAction ||
                            ""
                          }`}
                          className={`${row.isSent ? "disable-input" : ""}`}
                          name={inputs[i]}
                          onChange={(e) => handleChange(e, i)}
                          readOnly={!!row.isSent}
                        />

                        {!row.isSent ? (
                          <Button
                            appearance="primary"
                            onClick={(e) => handleSendMessage(e, row)}
                          >
                            {`${
                              row?.cardActionText?.includes("Message")
                                ? "Send Message"
                                : row?.cardActionText || "Send Message"
                            }`}
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              window.open(
                                `https://www.linkedin.com/messaging/compose/${row.publicIdentifier}`
                              )
                            }
                          >
                            View Message
                          </Button>
                        )}

                        <Tooltip content="Hide Record">
                          <IconButton
                            onClick={() => handleHideRecord(row)}
                            // need to add function to hide record
                            icon={EyeOffIcon}
                            color="muted"
                            marginLeft={10}
                          ></IconButton>
                        </Tooltip>
                      </Text>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Pane>
    </Pane>
  );
});

export default Table;
