import { Button, Pane, toaster } from "evergreen-ui";
import "./Welcome.css";
import Cookies from "js-cookie"; // Install this library if you want to use it
import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiEndPoint } from "../../../utils";
import NotLoggedInLinkedIn from "../NotLoggedInLinkedIn";
import { useNavigate, useSearchParams } from "react-router-dom";
/**
 * @desc React component
 * @param {Object} Props
 * @returns Table component
 */
let isSearchPressed = false;
function Welcome() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  let linkedInUser = {};
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(null);

  useEffect(() => {
    const verifySubscription = async () => {
      if (sessionId) {
        try {
          let user = localStorage.getItem("user");
          const res = await fetch(`${apiEndPoint}/auth/verify-subscription`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("jwt")}`,
            },
            body: JSON.stringify({ session_id: sessionId }),
          });
          const data = await res.json();
          if (data.subscriptionStatus === "active") {
            user = JSON.parse(user);
            user.subscriptionStatus = "active";
            localStorage.setItem("user", JSON.stringify(user));
            toaster.success("Subscription activated successfully!");
          } else {
            toaster.danger("Failed to verify subscription.");
          }
        } catch (error) {
          toaster.danger("Error verifying subscription.");
        }
      }
    };

    verifySubscription();
  }, [sessionId]);
  // Function to request LinkedIn user data
  const fetchLinkedInUserData = () => {
    window.postMessage({ action: "getLoggedInUser" }, "*");
    // setTimeout(() => {
    //   if (isExtensionInstalled === null) {
    //     setIsExtensionInstalled(false);
    //     toaster.danger("extension is not install please install it")
    //     navigate("/lime-instructions");
    //   }
    // }, 5000); // 1 second timeout
  };

  function categorizeData(data) {
    const birthdays = [];
    const jobChanges = [];
    const workAnniversaries = [];

    Array.isArray(data) &&
      data.forEach((item) => {
        if (item.type === "BIRTHDAY_PROP") {
          birthdays.push(item);
        } else if (item.type === "JOB_CHANGE_PROP") {
          jobChanges.push(item);
        } else if (item.type === "WORK_ANNIVERSARY_PROP") {
          workAnniversaries.push(item);
        }
      });

    return {
      birthdays,
      jobChanges,
      workAnniversaries,
    };
  }

  useEffect(() => {
    fetchLinkedInUserData();
    const handleMessage = (event) => {
      if (event.data && event.data.type === "loggedInUserResponse") {
        setIsExtensionInstalled(true);
        if (event.data.userData) {
          getLoggedInUser(event.data.userData);
          // setUserData(event.data.userData);
          setError(null);
        } else {
          setError(event.data.error || "An unknown error occurred");
        }
      } else if (event.data.type === "getNotificationsResponse") {
        setIsExtensionInstalled(true);
        // Change from action to type
        if (event.data.data) {
          let initialMode = true,
            typeIndex = 0,
            count = 10,
            notificationTypes = {
              job_changes: {
                key: "job_changes",
                type: "JOB_CHANGE_PROP",
                start: 0,
                count,
                isInitalDone: false,
                isDone: false,
              },
              work_anniversaries: {
                key: "work_anniversaries",
                type: "WORK_ANNIVERSARY_PROP",
                start: 0,
                count,
                isInitalDone: false,
                isDone: false,
              },
              birthdays: {
                key: "birthdays",
                type: "BIRTHDAY_PROP",
                start: 0,
                count,
                isInitalDone: false,
                isDone: false,
              },
            };
          // notificationKeys = Object.keys(notificationTypes),
          let results = categorizeData(event.data.data[1]);
          new Promise(async (done) => {
            let token = localStorage.getItem("jwt");
            let loggedInUser = localStorage.getItem("linkedInUser");
            loggedInUser = JSON.parse(loggedInUser);
            let response = await callApi(
              "/notifications/check-cards",
              { body: JSON.stringify(results), method: "POST" },
              { liuser: loggedInUser._id, authorization: "Bearer " + token }
            );
            if (response.data) {
              for (let [key, val] of Object.entries(response.data)) {
                if (val.allMatched) {
                  // notificationTypes[key].isDone = true;
                  results[key] = [];
                  continue;
                }
                if (val.newRecords.length) {
                  let dataSet = [];
                  for (let urn of val.newRecords) {
                    let result = results[key].find((x) => x.entityUrn === urn);
                    if (result) dataSet.push(result);
                  }
                  results[key] = dataSet;
                }
              }
            }

            saveNotifications(token, loggedInUser._id, event.data.data[1]);
           
            // toaster.success('Refresh complete!', {
            //   // description: 'No new notifications were found.',
            //   duration: 6,
            // });
            return done(results);
          });
        } else if (event.data.error) {
          setError(event.data.error);
        }
      } else if (event.data?.type) {
      }
    };

    // Listen for messages from the background script
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const saveNotifications = async (token, _id, requestData) => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      liuser: _id,
      authorization: "Bearer " + token,
    };

    let req = {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: new Headers(headers),
    };
    const res = await fetch(apiEndPoint + "/notifications/add", req).catch(
      (error) => {}
    );

    await res.json();
  };
  // Request notifications from the extension
  useEffect(() => {
    window.postMessage({ action: "getNotifications" }, "*");
  }, []);

 

  const getLoggedInUser = async (resp) => {
    try {
      const result = {
        firstName: resp.firstName,
        lastName: resp.lastName,
        country: resp.geoCountryName,
        headline: resp.headline,
        summary: resp.summary,
        entityUrn: resp.entityUrn,
        industryName: resp.industryName,
        profileId:
          resp.entityUrn &&
          (resp.entityUrn || "")
            .replace("urn:li:fsd_profile:", "")
            .replace("urn:li:fs_profile:", ""),
        location: resp.geoLocationName,
        publicIdentifier: resp.miniProfile && resp.miniProfile.publicIdentifier,
        memberId:
          resp &&
          resp.miniProfile &&
          (resp.miniProfile.objectUrn || "").replace("urn:li:member:", ""),
        profilePicture:
          resp.miniProfile &&
          resp.miniProfile.picture &&
          resp.miniProfile.picture["com.linkedin.common.VectorImage"] &&
          resp.miniProfile.picture["com.linkedin.common.VectorImage"].rootUrl +
            resp.miniProfile.picture["com.linkedin.common.VectorImage"]
              .artifacts[2].fileIdentifyingUrlPathSegment,
      };
      let token = localStorage.getItem("jwt");

      let resultRes = await callApi(
        "/notifications/init",
        {
          method: "POST",
          body: JSON.stringify({ userDetails: result }),
        },
        {},
        token
      );
      if (resultRes.user) {
        linkedInUser = resultRes.user;
      }

      linkedInUser = { ...linkedInUser, ...result };
      localStorage.setItem("linkedInUser", JSON.stringify(linkedInUser));
      Cookies.set("linkedInUser", linkedInUser?._id);

      setCount(resultRes.user?._id);
      return linkedInUser;
    } catch (e) {
    }
  };
  function callApi(
    api,
    data = { method: "POST" },
    reqHeaders = {},
    token = false
  ) {
    return new Promise(async (resolve, reject) => {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...reqHeaders,
      };
      if (token) {
        headers.authorization = "Bearer " + token;
      }
      let req = {
        ...data,
        headers: new Headers(headers),
      };
      const res = await fetch(apiEndPoint + api, req).catch((error) =>
        reject(error)
      );
      return resolve(await res.json());
    });
  }
  async function setCount(_id) {
    try {
      let token = localStorage.getItem("jwt");
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        liuser: _id,
        authorization: "Bearer " + token,
      };
      let req = {
        method: "GET",
        headers: new Headers(headers),
      };
      const res = await fetch(apiEndPoint + "/notifications/count", req).catch(
        (error) =>{}
      );
      let result = await res.json();

      return result;
    } catch (error) {
      return false;
    }
  }

  return (
    <>
      {error ? (
        <NotLoggedInLinkedIn />
      ) : (
        <Pane>
          <Pane
            background="#fff"
            boxShadow="0px 4px 20px rgba(0, 0, 0, 0.06)"
            style={{ borderRadius: "5px 5px 5px 5px" }}
            paddingTop={0}
          >
            <div
              style={{
                alignItems: "end",
                display: "grid",
                // gridTemplateColumns: '1fr 1fr',
                padding: "20px",
              }}
            >
              <h1>Welcome</h1>

              <h2>Thanks for trying LiMe. We hope you love it.</h2>
              {/* <h3>You have started your 30 day trial.</h3> */}
              <p>
                The Notifications tab contains notifications from your network
                only.
              </p>
              <p>
                The Feed tab contains the feeds from the people in your Watch
                List.
              </p>
              <p>
                To add a person or contact to your Watch List, navigate to the
                profile on LinkedIn, then click the LiMe icon and click Add.
              </p>
            </div>
          </Pane>
        </Pane>
      )}
    </>
  );
}

export default Welcome;
