import React, { useState, useEffect } from "react";
import { Pane, Button, Text, toaster } from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import { config } from "../../../config/environment";
import Cookies from "js-cookie";
import { loadStripe } from "@stripe/stripe-js";

// Initialize Stripe with the publishable key from environment config
const stripePromise = loadStripe(config.stripePublishableKey);

// API request helper
const makeApiRequest = async (endpoint, options = {}) => {
  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("jwt")}`,
    },
    ...options,
  };

  const response = await fetch(
    `${config.apiEndpoint}${endpoint}`,
    defaultOptions
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "API request failed");
  }

  return data;
};

function Package() {
  const [isTrialActive, setIsTrialActive] = useState(false);
  const [trialDays, setTrialDays] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user") || "null");

  useEffect(() => {
    const checkTrialStatus = async () => {
      try {
        const data = await makeApiRequest("/auth/check-trial-status");
        setIsTrialActive(data.isTrialActive);
        setTrialDays(data.remainingTrialDays);
      } catch (error) {
        toaster.danger("Failed to check trial status.");
      }
    };
    if (user?.subscriptionStatus != "active") {
      checkTrialStatus();
    } else {
      navigate("/welcome");
    }
  }, []);

  const handleStripeCheckout = async (endpoint) => {
    setLoading(true);
    try {
      const stripe = await stripePromise;
      const data = await makeApiRequest(endpoint, {
        method: "POST",
        body: JSON.stringify({ userId: user?._id }),
      });

      if (data.id) {
        await stripe.redirectToCheckout({ sessionId: data.id });
      } else {
        toaster.danger("Failed to initiate checkout.");
      }
    } catch (error) {
      toaster.danger(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

// Resume subscription function
async function resumeSubscription() {
  if (user) {
    const userId = user._id;
    try {
      await makeApiRequest("/auth/resume-subscription", {
        method: "POST",
        body: JSON.stringify({ userId }),
      });

      user.subscriptionStatus = "active";
      localStorage?.setItem("user", JSON.stringify(user));
      setIsTrialActive(false);
      toaster.success("Subscription resumed successfully!");
      window.location.href = "/welcome";
    } catch (error) {
      toaster.error(error.message || "Failed to resume subscription.");
    }
  }
}

  const handleSubscribe = () =>
    handleStripeCheckout("/auth/create-checkout-session");
  const handleRenew = () =>
    handleStripeCheckout("/auth/create-renew-checkout-session");

  const renderSubscriptionStatus = () => {
    if (isTrialActive && user?.subscriptionStatus === "trial") {
      if (trialDays > 0) {
        return (
          <Text color="green">{`You are on a ${trialDays}-day free trial.`}</Text>
        );
      }
      return (
        <Text color="red">
          Your trial has ended. Please subscribe to continue.<br/><br/>
        </Text>
      );
    }

    if (user?.subscriptionStatus === "inactive") {
      return (
        <Text color="red">
          Your subscription is inactive. Please renew your subscription to
          access premium features.<br/><br/>
        </Text>
      );
    }

    return null;
  };



  const renderActionButton = () => {
    if (isTrialActive && user?.subscriptionStatus === "trial") {
      return (
        <Pane width="60%">
          <Text size={500} marginBottom={16}>
            Our subscription package includes all premium features, updates, and
            support.<br/><br/>
          </Text>
          <Button
            appearance="primary"
            size="large"
            isLoading={loading}
            onClick={handleSubscribe}
          >
            Subscribe Now
          </Button>
        </Pane>
      );
    }

    if (user?.subscriptionStatus === "inactive") {
      return (
        <Pane width="60%">
          <Text size={500} marginBottom={16}>
            You can renew your subscription to continue enjoying our services.
          </Text>
          <Button
            appearance="primary"
            size="large"
            isLoading={loading}
            onClick={handleRenew}
          >
            Renew Subscription
          </Button>
        </Pane>
      );
    }

    return null;
  };

  const renderResumeSubscription=()=>{
    if (user &&  user?.subscriptionStatus == "canceled") {
      return (
        <Pane width="60%">
          <Text size={500} marginBottom={16}>
          Resume your subscription to regain access to all premium features, regular updates, and dedicated support.<br/>Don't miss out—reactivate now to enjoy the full benefits!<br/><br/>
          </Text>
          <Button
            appearance="primary"
            size="large"
            isLoading={loading}
            onClick={resumeSubscription}
          >
            Resume Subscription
          </Button>
        </Pane>
      );
    }

    if (user?.subscriptionStatus === "inactive") {
      return (
        <Pane width="60%">
          <Text size={500} marginBottom={16}>
            You can renew your subscription to continue enjoying our services.
          </Text>
          <Button
            appearance="primary"
            size="large"
            isLoading={loading}
            onClick={handleRenew}
          >
            Renew Subscription
          </Button>
        </Pane>
      );
    }

    return null;
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      gridGap={20}
    >
      <Text size={700} fontWeight="bold">
        Choose Your Subscription Package
      </Text>

      {renderSubscriptionStatus()}
      {renderActionButton()}
      {renderResumeSubscription()}


      {isTrialActive && trialDays > 0 && (
        <Pane
          marginTop={10}
          cursor="pointer"
          onClick={() => navigate("/welcome")}
        >
          <Text color="blue">Return to Dashboard</Text>
        </Pane>
      )}
    </Pane>
  );
}

export default Package;
