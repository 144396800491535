import React, { useState } from 'react';
import { Text, Button, Pane } from 'evergreen-ui';

import logo from '../../assets/img/icon.svg';
import { apiEndPoint } from '../../utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
function NotLoggedInLinkedIn() {
  const [linkedInUser, setLinkedInUser] = useState(false);
  const [state, setState] = useState({});
  const [error,setError]=useState();
  const navigate = useNavigate();
  

  useEffect(() => {
    // Define interval variable
    let intv;

    // Define handleMessage function to check for the specific event
    const handleMessage = (event) => {
      if (event.data.type === "getCsrfToken" && event.data.data) {
        console.log(event.data.data);
        console.log("Running");
        clearInterval(intv); // Clear the interval when the condition is met
        setError(false);
        navigate("/welcome");
      } else if(event.data.type === "getCsrfToken" && event.data.error) {
        console.log("event.data.error",event.data.error)
        setError(true);
      }
    };

    // Set interval to trigger a message check every 5 seconds
    intv = setInterval(() => {
      window.addEventListener("message", handleMessage);
      window.postMessage({ type: "getCsrfToken" }, "*");
    }, 5000);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("message", handleMessage);
      clearInterval(intv); // Clear interval on cleanup
    };
  }, [navigate]);
  
  



  return  (
    <Pane
      width="100vw"
      height="100vh"
      display="grid"
      gridTemplateColumns="500px"
      placeContent="center"
      textAlign="center"
      justifyItems="center"
    >
      <img style={{ width: 80, marginBottom: 20 }} src={logo} alt="logo" />
      <Text size={600}>
        It appears you are not logged into LinkedIn on this browser. Please log
        into your account to use this extension.
      </Text>
      <a
        target="_blank"
        href="https://www.linkedin.com/"
        style={{ marginTop: 20, textDecoration: 'inherit' }}
      >
        <Button appearance="primary">Go to LinkedIn</Button>
      </a>
    </Pane>
  )
}

export default NotLoggedInLinkedIn;
