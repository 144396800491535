import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";
import {
  Pane,
  FeedIcon,
  Checkbox,
  MoreIcon,
  Button,
  IconButton,
  RefreshIcon,
  TextInputField,
  DownloadIcon,
  Popover,
  Menu,
  NotificationsIcon,
  PersonIcon,
  ChatIcon,
  DisableIcon,
  FollowingIcon,
  FollowerIcon,
  Position,
  Avatar,
  CaretDownIcon,
  CaretUpIcon,
  toaster,
  Dialog,
  Tooltip,
  TagIcon,
  Badge,
  Text,
  RemoveIcon,
  Select,
  TagInput,
} from "evergreen-ui";
import { debounce } from "debounce";
import axios from "axios";
import "./WatchList.css";
import Pagination from "./components/Pagination";
import Sorting from "./components/Sorting";

import {
  formatTimeStamp,
  cleanStr,
  limeTextConvertor,
  readStorage,
  apiEndPoint,
} from "../../../utils";
import { useNavigate } from "react-router-dom";

/**
 * @desc React component
 * @param {Object} Props
 * @returns Table component
 */
let isSearchPressed = false;
const WatchList = forwardRef((props, ref) => {
  // STATES
  useImperativeHandle(ref, () => ({
    refreshData() {
      if (userId?._id) {
        fetchData();
      }
    },
  }));

  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [connections, setConnections] = useState([]);
  const [showRemove, setRemove] = useState(false);
  const [toRemove, setToRemove] = useState();
  const token = localStorage.getItem("jwt");
  let loggedInUser = localStorage.getItem("user");
  loggedInUser = JSON.parse(loggedInUser);

  const user = localStorage.getItem("profile");
  const userId = JSON.parse(user);
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({
    count: 25,
    currentPage: 1,
  });
  const [totalResults, setTotalResults] = useState(0);
  const [sorting, setSorting] = useState(
    localStorage.getItem("selectedWatchListSort") || "createdAt_desc"
  );

  const [inputSearch, setInputSearch] = useState("");
  const [fetchSearch, setFetchSearch] = useState("");
  const [linkedInUser, setLinkedInUser] = useState(true);
  // TODO
  const [fetchLoading, setFetchLoading] = useState([]);
  const [inputs, setInputsState] = useState([]);

  const [options] = useState(
    [
      { label: "Anniversaries", value: "WORK_ANNIVERSARY_PROP" },
      { label: "Birthdays", value: "BIRTHDAY_PROP" },
      { label: "Job Changes", value: "JOB_CHANGE_PROP" },
    ].map((data) => data)
  );

  const [selectedItemsState, setSelectedItems] = useState(
    JSON.parse(localStorage.getItem("selectedItemsState")) || []
  );

  const [selectedItemNamesState, setSelectedItemNames] = useState(null);
  // -----
  const componentMounted = useRef(true);
  /**
   * @desc Send a fetch req to the server to get back notifications
   * @param {Number} [start] The start index of the results for pagination
   * @param {Number} [count] The no. of results per page for pagination
   * @param {String} [sortBy] the field to sort in
   * @param {String} [sortOrder] the order to sort in
   * @param {Array} [searchIn] the fields for text search
   * @param {Array} [search] the texts to search per field
   * @return {Object} - notifications data from the server as per the filters
   */
  // function getStorage(key = 'token') {
  //   return new Promise((resolve) => {
  //     if (key) chrome.storage.sync.get(key, (resp) => resolve(resp[key]));
  //     else chrome.storage.sync.get((resp) => resolve(resp[key]));
  //   });
  // }
  function fetchWatchList(start, count, sortBy, sortOrder, fetchSearch) {
    return new Promise(async (resolve) => {
      let searchUrl = ``;
      if (fetchSearch !== "") {
        searchUrl = `&search=${fetchSearch}`;
      }
      const { data } = await axios.get(
        `${apiEndPoint}/watchlist?start=${start}&count=${count}&sortBy=${sortBy}&sortOrder=${sortOrder}${searchUrl}`,
        {
          headers: {
            liuser: userId?._id,
            authorization: "Bearer " + token,
          },
        }
      );

      return resolve(data);
    });
  }

  // -----

  /**
   * @desc Calls fetchNotifications and sets the results to component states
   */

  const fetchData = async () => {
    //setLoading(true);
    const { data, meta } = await fetchWatchList(
      pagination.count * (isSearchPressed ? 0 : pagination.currentPage - 1),
      pagination.count,
      sorting.split("_")[0],
      sorting.split("_")[1] === "asc" ? 1 : -1,
      fetchSearch
    );
    isSearchPressed = false;
    const connectionsArr = data.watchlists;

    setTotalResults(meta.totalResults);
    setConnections(connectionsArr);
    setFetchLoading(connectionsArr.map((cur) => false));
    setLoading(false);
  };
  const handleRemove = async () => {
    setRemove(false);
    const { data } = await axios.delete(
      `${apiEndPoint}/watchlist/${toRemove.original._id}`,
      {
        headers: {
          liuser: userId?._id,
          authorization: "Bearer " + token,
        },
      }
    );
    if (data.code == 200) {
      if (userId?._id) {
        fetchData();
      }
    }
  };
  // USE EFFECTS

  // runs when component mounts

  // runs when any of the following state changes
  useEffect(() => {
    if (userId?._id) {
      fetchData();
      // toaster.success("Refresh complete!", {
      //   // description: 'No new notifications were found.',
      //   duration: 6,
      // });
    }
  }, [pagination, sorting, fetchSearch]);

  // HANDLERS

  /**
   * @desc Sends Message to bckg to collect complete data of the current row connection and updates the server and current state accordingly
   * @param {Object} [cell] - The cell object passed in table column
   */

  const handleFetchSearch = (e) => {
    const { value } = e.target;
    isSearchPressed = true;
    setFetchSearch(value);
  };
  const gotoFeeds = (profile) => {
    navigator("/feeds?search=" + profile.fullName);
  };
  const gotoNotification = (profile) => {
    navigator("/notifications?search=" + profile.fullName);
  };
  const handler = useCallback(debounce(handleFetchSearch, 300), []);

  const handleInputSearch = (e) => {
    const { value } = e.target;
    setInputSearch(value);
    isSearchPressed = true;
    handler(e);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        disableSortBy: true,
        accessor: "profilePicture",
        className: "profilePictureCell",
        Cell: (row) => {
          return (
            <a
              target={`_blank`}
              href={`https://www.linkedin.com/${
                row.cell.row.original.profileType == "person"
                  ? `in/${row.cell.row.original.publicIdentifier}`
                  : `company/${row.cell.row.original.profileId}`
              }/`}
            >
              <Avatar
                className="avatar-img"
                src={row.value}
                size={50}
                name={row.cell.row.original.fullName}
              />
            </a>
          );
        },
      },
      {
        Header: "Name",
        accessor: "fullName", // accessor is the "key" in the data
        className: "nameCell",
      },
      {
        Header: "Company",
        accessor: "company",
        className: "companyCell",
        Cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <Pane>
              {["", "false", false].includes(original.company)
                ? ""
                : original.company}
            </Pane>
          );
        },
      },
      {
        Header: "Title",
        accessor: "companyTitle",
        className: "companyTitleCell",
        Cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <Pane>
              {["", "false", false].includes(original.companyTitle)
                ? ""
                : original.companyTitle}
            </Pane>
          );
        },
      },
      {
        Header: "Location",
        accessor: "location",
        className: "locationCell",
      },
      {
        Header: "Industry",
        accessor: "industryName",
        className: "industryNameCell",
      },
      {
        Header: "Contact Info",
        accessor: "contact",
        disableSortBy: true,
        className: "contactInfoCell",
        Cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <Pane>
              {original.contactInfo.emailAddress ? (
                <Pane>
                  <a href={`mailto:${original.contactInfo.emailAddress}`}>
                    {original.contactInfo.emailAddress}
                  </a>
                </Pane>
              ) : (
                <Pane></Pane>
              )}
              <Pane>
                {(original.contactInfo.phoneNumbers &&
                  original.contactInfo.phoneNumbers[0]) ||
                  ""}
              </Pane>
              <Pane>{original.contactInfo.address || ""}</Pane>
            </Pane>
          );
        },
      },
      {
        Header: "",
        accessor: "bell",
        disableSortBy: true,
        Cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <Pane className="flex icons-set">
              <Tooltip
                content={`View ${
                  original.profileType == "person"
                    ? original.firstName
                    : original.company
                }'s feed`}
              >
                <FeedIcon
                  cursor="pointer"
                  marginRight="20px"
                  onClick={() => gotoFeeds(original)}
                ></FeedIcon>
              </Tooltip>
              <Tooltip
                content={`View ${
                  original.profileType == "person"
                    ? original.firstName
                    : original.company
                }'s notifications`}
              >
                <NotificationsIcon
                  cursor="pointer"
                  onClick={() => gotoNotification(original)}
                />
              </Tooltip>
            </Pane>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <Popover
              position={Position.BOTTOM_LEFT}
              content={({ close }) => (
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() => {
                        setToRemove({ original: original });
                        setRemove(true);
                      }}
                      intent="danger"
                      icon={RemoveIcon}
                    >
                      Remove
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              )}
            >
              <MoreIcon cursor="pointer" marginX="auto" />
            </Popover>
          );
        },
        className: "actionsCell",
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data: connections,
      disableMultiSort: true,
      manualSortBy: true,
    },
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      desc ? setSorting(`${id}_desc`) : setSorting(`${id}_asc`);
    } else {
      setSorting(
        localStorage.getItem("selectedWatchListSort") || "createdAt_desc"
      );
    }
  }, [sortBy]);

  return (
    <Pane>
      {showRemove ? (
        <Dialog
          isShown={showRemove ? true : false}
          hasHeader={false}
          intent="danger"
          onCloseComplete={() => setRemove(false)}
          onConfirm={handleRemove}
          confirmLabel="Remove"
        >
          <div className="helphead">
            <h1>Remove</h1>
          </div>
          <div className="helpmsg">
            <p>
              Are you sure you want to remove {toRemove.original.fullName} from
              your Watch List?
            </p>
          </div>
        </Dialog>
      ) : null}
      <Pane
        background="#fff"
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.06)"
        style={{ borderRadius: "5px 5px 5px 5px" }}
        paddingTop={0}
      >
        <div
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "250px auto",
              alignItems: "center",
              gridGap: "30px",
            }}
          >
            <TextInputField
              label=""
              name={"search"}
              onChange={handleInputSearch}
              value={inputSearch}
              width={250}
              marginBottom={2}
              placeholder="Search all columns"
            />
          </div>
          <Pane
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Sorting
              sorting={sorting}
              setSorting={setSorting}
              dbSortKey={`selectedWatchListSort`}
              sortList={[
                { value: "createdAt_desc", label: "Newest first" },
                { value: "createdAt_asc", label: "Newest last" },
              ]}
            />
            <Pagination
              pagination={pagination}
              setPagination={setPagination}
              totalResults={totalResults}
              // handleDownload={handleDownload}
            />
          </Pane>
        </div>
        <div className="tableContainer">
          {loading && (
            <div className="tableLoadingContainer">
              <Loading />
            </div>
          )}
          <table {...getTableProps()} className="table">
            <thead className="tableHeader">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, i) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={i}
                    >
                      {i === 0 && Object.keys(selectedRowIds).length > 0
                        ? null
                        : column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span style={{ marginLeft: 5 }}>
                            <CaretDownIcon transform="translateY(3px)" />
                          </span>
                        ) : (
                          <span style={{ marginLeft: 5 }}>
                            <CaretUpIcon transform="translateY(3px)" />
                          </span>
                        )
                      ) : null}
                      {i === 0 && Object.keys(selectedRowIds).length > 0 ? (
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            alignItems: "center",
                            paddingLeft: 5,
                            gridGap: 5,
                          }}
                        >
                          <span>{column.render("Header")}</span>
                          <span style={{ marginLeft: 5, display: "inline" }}>
                            {" "}
                            {Object.keys(selectedRowIds).length}{" "}
                          </span>
                          <Popover
                            position={Position.BOTTOM_LEFT}
                            content={({ close }) => (
                              <Menu>
                                <Menu.Group>
                                  <Menu.Item
                                    onSelect={() => {
                                      const rowIdsArr =
                                        Object.keys(selectedRowIds);
                                      const selectedRows = rows.filter(
                                        (cur, i) =>
                                          rowIdsArr.includes(String(i))
                                      );
                                      openDialogBox(
                                        selectedRows.map((cur) => cur.original)
                                      );
                                    }}
                                    disabled={
                                      Object.keys(selectedRowIds).length > 25
                                        ? true
                                        : false
                                    }
                                    color={
                                      Object.keys(selectedRowIds).length > 25
                                        ? "rgba(0,0,0,.4)"
                                        : ""
                                    }
                                    icon={TagIcon}
                                  >
                                    Apply Tag
                                  </Menu.Item>

                                  {/* <Menu.Item
                                    onSelect={() => {
                                      const rowIdsArr =
                                        Object.keys(selectedRowIds);

                                      const selectedRows = rows.filter(
                                        (cur, i) =>
                                          rowIdsArr.includes(String(i))
                                      );

                                      setShowSendMessage(
                                        selectedRows.map((cur) => cur.original)
                                      );
                                    }}
                                    disabled={
                                      Object.keys(selectedRowIds).length > 25
                                        ? true
                                        : false
                                    }
                                    color={
                                      Object.keys(selectedRowIds).length > 25
                                        ? 'rgba(0,0,0,.4)'
                                        : ''
                                    }
                                    icon={ChatIcon}
                                  >
                                    Message
                                  </Menu.Item> */}

                                  {/* <Menu.Item
                                    onSelect={() => {
                                      const rowIdsArr =
                                        Object.keys(selectedRowIds);

                                      const selectedRows = rows.filter(
                                        (cur, i) =>
                                          rowIdsArr.includes(String(i))
                                      );
                                      setShowDisconnect(true);
                                      // setToDisconnect({
                                      //   original: selectedRows.map(
                                      //     (cur) => cur.original
                                      //   ),
                                      //   // entityUrn: original.entityUrn,
                                      //   // fullName: original.fullName,
                                      // });
                                    }}
                                    icon={DisableIcon}
                                  >
                                    Disconnect
                                  </Menu.Item> */}
                                  <Menu.Item
                                    onSelect={() => {
                                      const rowIdsArr =
                                        Object.keys(selectedRowIds);

                                      const selectedRows = rows.filter(
                                        (cur, i) =>
                                          rowIdsArr.includes(String(i))
                                      );
                                      handleFollow(
                                        selectedRows.map(
                                          (cur) => cur.original.publicIdentifier
                                        ),
                                        "followConnection",
                                        selectedRows.map(
                                          (cur) => cur.original.firstName
                                        )
                                      );
                                    }}
                                    icon={FollowingIcon}
                                  >
                                    Follow
                                  </Menu.Item>
                                  <Menu.Item
                                    onSelect={() => {
                                      const rowIdsArr =
                                        Object.keys(selectedRowIds);

                                      const selectedRows = rows.filter(
                                        (cur, i) =>
                                          rowIdsArr.includes(String(i))
                                      );
                                      handleFollow(
                                        selectedRows.map(
                                          (cur) => cur.original.publicIdentifier
                                        ),
                                        "unfollowConnection",
                                        selectedRows.map(
                                          (cur) => cur.original.firstName
                                        )
                                      );
                                    }}
                                    icon={FollowerIcon}
                                  >
                                    Unfollow
                                  </Menu.Item>
                                </Menu.Group>
                              </Menu>
                            )}
                          >
                            <MoreIcon
                              cursor="pointer"
                              marginX="auto"
                              marginLeft={5}
                            />
                          </Popover>
                        </div>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${cell.column.className || ""}`}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Pane>
    </Pane>
  );
});

export default WatchList;
