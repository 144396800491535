import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Dashboard/Login";
import Signup from "./pages/Dashboard/Signup";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Welcome from "./pages/Dashboard/Table/Welcome";
import { useAuth } from "./hooks/useAuth";
import Header from "./components/Header";
import Feed from "./pages/Dashboard/Table/Feed";
import Notification from "./pages/Dashboard/Table/Notifications";
import WatchList from "./pages/Dashboard/Table/WatchList";
import Settings from "./pages/Dashboard/Settings";
import Profile from "./pages/Dashboard/Profile";
import Package from "./pages/Dashboard/Table/Package";
import NotFound from "./pages/NotFound";
import LimeInstructions from "./pages/Dashboard/Table/LimeInstructions";
// import CheckExtension from "./pages/Dashboard/Table/CheckExtension";
import Home from "./pages/Home";  // Add this import

// PrivateRoute Component for Protected Routes
function PrivateRoute({ children }) {
  const isAuthenticated = useAuth();
  return isAuthenticated ? (
    <>
      <Header />
      <div style={{ margin: "30px" }}>{children}</div>
    </>
  ) : (
    <Navigate to="/login" />
  );
}

function App() {
  const [isTrialActive, setIsTrialActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      if (
        userData.subscriptionStatus == "trial" &&
        userData.remainingTrialDays == 0
      ) {
        const trialStatus =
          userData.subscriptionStatus == "trial" &&
          userData.remainingTrialDays == 0;
        setIsTrialActive(trialStatus);
      } else if (userData.subscriptionStatus == "canceled") {
        setIsTrialActive(true);
      } else if (userData.subscriptionStatus == "inactive") {
        setIsTrialActive(true);
      }
    } else {
    }
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/lime-instructions" element={<LimeInstructions />} />
          {/* <Route path="/test" element={<CheckExtension />} /> */}

          {/* Always Available Route */}
          <Route
            path="/package"
            element={
              <PrivateRoute>
                <Package />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          {/* Conditionally Rendered Routes for Non-Trial Users */}
          {isTrialActive == false && (
            <>
              <Route
                path="/welcome"
                element={
                  <PrivateRoute>
                    <Welcome />
                  </PrivateRoute>
                }
              />
              <Route
                path="/feeds"
                element={
                  <PrivateRoute>
                    <Feed />
                  </PrivateRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <PrivateRoute>
                    <Notification />
                  </PrivateRoute>
                }
              />
              <Route
                path="/watch-list"
                element={
                  <PrivateRoute>
                    <WatchList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
            </>
          )}

          {/* 404 Not Found Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;